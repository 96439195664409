import dynamic from 'next/dynamic';

export const iconMap = {
  'address-book': dynamic(() => import('@/icons/address-card.svg')),
  'address-card': dynamic(() => import('@/icons/address-book.svg')),
  'arrow-down-a-z': dynamic(() => import('@/icons/arrow-down-a-z.svg')),
  'arrow-right-arrow-left': dynamic(
    () => import('@/icons/arrow-right-arrow-left.svg')
  ),
  'arrow-up-a-z': dynamic(() => import('@/icons/arrow-up-a-z.svg')),
  'badge-check': dynamic(() => import('@/icons/badge-check.svg')),
  ban: dynamic(() => import('@/icons/ban.svg')),
  bold: dynamic(() => import('@/icons/bold.svg')),
  briefcase: dynamic(() => import('@/icons/briefcase.svg')),
  'calendar-days': dynamic(() => import('@/icons/calendar-days.svg')),
  'chair-office': dynamic(() => import('@/icons/chair-office.svg')),
  'chart-mixed': dynamic(() => import('@/icons/chart-mixed.svg')),
  check: dynamic(() => import('@/icons/check.svg')),
  'chevron-down': dynamic(() => import('@/icons/chevron-down.svg')),
  'chevron-left': dynamic(() => import('@/icons/chevron-left.svg')),
  'chevron-right': dynamic(() => import('@/icons/chevron-right.svg')),
  'chevron-up': dynamic(() => import('@/icons/chevron-up.svg')),
  'circle-check': dynamic(() => import('@/icons/circle-check.svg')),
  'circle-minus': dynamic(() => import('@/icons/circle-minus.svg')),
  'circle-notch': dynamic(() => import('@/icons/circle-notch.svg')),
  'circle-play': dynamic(() => import('@/icons/circle-play.svg')),
  clock: dynamic(() => import('@/icons/clock.svg')),
  close: dynamic(() => import('@/icons/times.svg')),
  'cloud-arrow-down': dynamic(() => import('@/icons/cloud-arrow-down.svg')),
  'comment-dots': dynamic(() => import('@/icons/comment-dots.svg')),
  edit: dynamic(() => import('@/icons/edit.svg')),
  ellipsis: dynamic(() => import('@/icons/ellipsis.svg')),
  'ellipsis-stroke': dynamic(() => import('@/icons/ellipsis-stroke.svg')),
  envelope: dynamic(() => import('@/icons/envelope.svg')),
  eye: dynamic(() => import('@/icons/eye.svg')),
  'facebook-f': dynamic(() => import('@/icons/facebook-f.svg')),
  fax: dynamic(() => import('@/icons/fax.svg')),
  'file-contract': dynamic(() => import('@/icons/file-contract.svg')),
  'file-lines': dynamic(() => import('@/icons/file-lines.svg')),
  'file-medical': dynamic(() => import('@/icons/file-medical.svg')),
  'file-plus': dynamic(() => import('@/icons/file-plus.svg')),
  'file-waveform': dynamic(() => import('@/icons/file-waveform.svg')),
  gear: dynamic(() => import('@/icons/gear.svg')),
  google: dynamic(() => import('@/icons/google.svg')),
  'grid-dividers': dynamic(() => import('@/icons/grid-dividers.svg')),
  h1: dynamic(() => import('@/icons/h1.svg')),
  h2: dynamic(() => import('@/icons/h2.svg')),
  h3: dynamic(() => import('@/icons/h3.svg')),
  h4: dynamic(() => import('@/icons/h4.svg')),
  history: dynamic(() => import('@/icons/history.svg')),
  'hospital-user': dynamic(() => import('@/icons/hospital-user.svg')),
  'hourglass-end': dynamic(() => import('@/icons/hourglass-end.svg')),
  'house-blank': dynamic(() => import('@/icons/house-blank.svg')),
  'house-chimney-medical': dynamic(
    () => import('@/icons/house-chimney-medical.svg')
  ),
  info: dynamic(() => import('@/icons/info.svg')),
  'info-circle': dynamic(() => import('@/icons/circle-info.svg')),
  instagram: dynamic(() => import('@/icons/instagram.svg')),
  'invoice-dollar': dynamic(() => import('@/icons/file-invoice-dollar.svg')),
  italic: dynamic(() => import('@/icons/italic.svg')),
  link: dynamic(() => import('@/icons/link.svg')),
  linkedin: dynamic(() => import('@/icons/linkedin-in.svg')),
  'list-check': dynamic(() => import('@/icons/list-check.svg')),
  'list-ol': dynamic(() => import('@/icons/list-ol.svg')),
  'list-ul': dynamic(() => import('@/icons/list-ul.svg')),
  messages: dynamic(() => import('@/icons/messages.svg')),
  microphone: dynamic(() => import('@/icons/microphone.svg')),
  'microphone-slash': dynamic(() => import('@/icons/microphone-slash.svg')),
  'money-bill-1': dynamic(() => import('@/icons/money-bill-1.svg')),
  notdef: dynamic(() => import('@/icons/notdef.svg')),
  'note-medical': dynamic(() => import('@/icons/note-medical.svg')),
  'number-asc': dynamic(() => import('@/icons/arrow-up-9-1.svg')),
  'number-desc': dynamic(() => import('@/icons/arrow-up-1-9.svg')),
  'paper-plane': dynamic(() => import('@/icons/paper-plane.svg')),
  paragraph: dynamic(() => import('@/icons/paragraph.svg')),
  patient: dynamic(() => import('@/icons/patient.svg')),
  'pdf-solid': dynamic(() => import('@/icons/file-pdf-solid.svg')),
  'pen-to-square': dynamic(() => import('@/icons/pen-to-square.svg')),
  phone: dynamic(() => import('@/icons/phone.svg')),
  'phone-hangup': dynamic(() => import('@/icons/phone-hangup.svg')),
  plus: dynamic(() => import('@/icons/plus.svg')),
  'quote-left': dynamic(() => import('@/icons/quote-left.svg')),
  reply: dynamic(() => import('@/icons/reply.svg')),
  'right-from-bracket': dynamic(() => import('@/icons/right-from-bracket.svg')),
  'right-to-bracket': dynamic(() => import('@/icons/right-to-bracket.svg')),
  save: dynamic(() => import('@/icons/floppy-disk.svg')),
  search: dynamic(() => import('@/icons/search.svg')),
  share: dynamic(() => import('@/icons/share.svg')),
  'sign-out': dynamic(() => import('@/icons/sign-out.svg')),
  star: dynamic(() => import('@/icons/star.svg')),
  strikethrough: dynamic(() => import('@/icons/strikethrough.svg')),
  'text-size': dynamic(() => import('@/icons/text-size.svg')),
  timer: dynamic(() => import('@/icons/timer.svg')),
  trash: dynamic(() => import('@/icons/trash.svg')),
  twitter: dynamic(() => import('@/icons/twitter.svg')),
  underline: dynamic(() => import('@/icons/underline.svg')),
  'upload-solid': dynamic(() => import('@/icons/upload-solid.svg')),
  user: dynamic(() => import('@/icons/user.svg')),
  'user-doctor': dynamic(() => import('@/icons/user-doctor.svg')),
  'user-minus': dynamic(() => import('@/icons/user-minus.svg')),
  'user-nurse': dynamic(() => import('@/icons/user-nurse.svg')),
  'user-pen': dynamic(() => import('@/icons/user-pen.svg')),
  'user-plus': dynamic(() => import('@/icons/user-plus.svg')),
  users: dynamic(() => import('@/icons/users.svg')),
  'users-medical': dynamic(() => import('@/icons/users-medical.svg')),
  video: dynamic(() => import('@/icons/video.svg')),
  'video-slash': dynamic(() => import('@/icons/video-slash.svg')),
  'volume-max': dynamic(() => import('@/icons/volume-max.svg')),
  youtube: dynamic(() => import('@/icons/youtube.svg')),
};

export const renderIcon = (icon: string) => {
  const mapped = iconMap[icon] || iconMap.notdef;

  if (!iconMap[icon]) {
    console.warn(` "${icon}" not found in iconMap`);
  }

  return mapped;
};

export type IconTypes = keyof typeof iconMap;
