import SVG from '@/components/data-display/SVG';

import LoadingIcon from '@/icons/spinner-third.svg';

import { Size } from '@/types/index';
import { classNames } from '@/utils/classNames';

// Use this in components where <SVG /> is already loaded
// and is rendering pure SVG icons.
export const LoadingSpinnerIcon = () => {
  return <LoadingIcon className="spin" />;
};

interface LoadingSpinnerProps {
  className?: string;
  dataTestId?: string;
  fillClassName?: string;
  sizeClassName?: string;
  spaceClassName?: string;
  size?: Size;
  wrapperClassName?: string;
}

// Use this in components all other instances
// where we need to apply svg styles.
const LoadingSpinner = ({
  className,
  dataTestId = 'loading-spinner',
  fillClassName,
  sizeClassName,
  spaceClassName,
  size,
  wrapperClassName,
  ...rest
}: LoadingSpinnerProps) => {
  const sizeCln = sizeClassName || 'w-12 h-12';
  const spaceCln = spaceClassName || 'mx-auto m-5';
  const fillCln = fillClassName || 'fill-current';
  const wrapperCln = wrapperClassName || 'flex justify-center py-5';

  return (
    <div data-testid={dataTestId} className={wrapperCln}>
      <SVG
        fill={undefined}
        loading={undefined}
        className={classNames(className, fillCln, sizeCln, spaceCln)}
        size={size}
        icon={<LoadingIcon className="spin" />}
        {...rest}
      />
    </div>
  );
};

export default LoadingSpinner;
