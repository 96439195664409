import { ReactNode } from 'react';

import Text from '@/components/data-display/Text';
import FullButton from '@/components/data-entry/FullButton';

export const DoctorMobileUnavailableLayout = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <>
      {/* TODO: Add notification here. */}

      <div className="flex flex-col items-center justify-center h-screen md:hidden w-8/12 mx-auto text-center space-y-5">
        <Text variant="h2">Thanks for using PocketMD!</Text>
        <Text variant="caption">
          Please use your desktop to access the doctor app.
        </Text>

        <FullButton href="/">Go To Patient App</FullButton>
      </div>

      <div className="hidden md:block h-full">{children}</div>
    </>
  );
};
