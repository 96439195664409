// We split this outside of the `defaultColors` and `darkModeColors` so that
// we get the color definitions closer to "single-source of truth" mental model,
// as we can apply tints and shades and do other manipulations and they are decoupled from the theme object.
//
// This will also make this setup more reusable between projects.

// Branding
export const primaryCol = {
  dark: '',
  light: '43,89,95',
};
export const primaryLightCol = {
  dark: '',
  light: '220,232,232',
};
export const primary9Col = {
  dark: '',
  light: '29,56,60',
};
export const secondaryCol = {
  dark: '',
  light: '156,54,50',
};
export const accentCol = {
  dark: '',
  light: '57,114,121',
};
export const neutralCol = {
  dark: '',
  light: '25,29,36',
};
export const warningCol = {
  dark: '',
  light: '251,189,35',
};
export const backgroundCol = {
  dark: '',
  light: '251,251,251',
};
export const backgroundSecondaryCol = {
  dark: '',
  light: '249,248,247',
};
export const borderCol = {
  dark: '',
  light: '218,218,218',
};
export const surfaceCol = {
  dark: '',
  light: '255, 255, 255',
};
export const surfaceNeutralCol = {
  dark: '',
  light: '245, 245, 245',
};
export const surfaceSecondaryCol = {
  dark: '',
  light: '249,248,246',
};
export const errorCol = {
  dark: '',
  light: '245,34,45',
};
export const infoCol = {
  dark: '',
  light: '0,134,255',
};
export const successCol = {
  dark: '',
  light: '2,134,47',
};
export const codeBackgroundCol = {
  dark: '',
  light: '50,54,68',
};

export const inactiveCol = '163,161,168';
export const ringCol = '50,50,44';
export const secondaryHoverCol = '';

export const svgFillCol = '51,54,68';
export const svgFillHoverCol = '43,89,95';
export const contrastSurfaceCol = '51,54,68';
export const contrastTextCol = '189,217,242';
export const textCol = {
  dark: '',
  light: '51,54,68',
};
export const textSecondaryCol = {
  dark: '',
  light: '160,160,160',
};
export const textPlaceholderCol = '160,160,160';
export const trueWhite = '255,255,255';
export const whiteCol = '255,255,255';
export const yellowCol = '184,240,56';

export const defaultColors = {
  accent: accentCol,
  background: backgroundCol,
  border: borderCol,
  contrastSurface: contrastSurfaceCol,
  // contrastSurfaceRGB: Color(contrastSurfaceCol).rgb().array().join(','),
  contrastText: contrastTextCol,
  error: errorCol,
  fadedPrimary: '238,198,255',
  // fadedPrimaryRGB: Color('#eec6ff').rgb().array().join(','),
  inactive: inactiveCol,
  // inactiveRGB: Color(inactiveCol).rgb().array().join(','),
  info: infoCol,
  // infoRGB: Color(infoCol).rgb().array().join(','),
  // ltAccentRGB: Color('#ffa1ee').rgb().array().join(','),
  ltBlue: '0,134,255',
  // ltBlueRGB: Color('0,134,255').rgb().array().join(','),
  ltGrey: '226,226,226',
  neutral: neutralCol,
  // ltGreyRGB: Color('#e2e2e2').rgb().array().join(','),
  placeholder: textPlaceholderCol,
  primary: primaryCol,
  'primary-9': primary9Col,
  'primary-light': primaryLightCol,
  // primaryRGB: Color(primaryCol).rgb().array().join(','),
  primaryShade: [
    // Color(primaryCol).lighten(0.67).hex(),
    // Color(primaryCol).lighten(0.62).hex(),
    // Color(primaryCol).lighten(0.25).hex(),
    // Color(primaryCol).hex(),
    // Color(primaryCol).darken(0.25).hex(),
    // Color(primaryCol).darken(0.5).hex(),
  ],
  ring: ringCol,
  secondary: secondaryCol,
  secondaryHover: secondaryHoverCol,
  success: successCol,
  // secondaryRGB: Color(secondaryCol).rgb().array().join(','),
  surface: surfaceCol,
  // surfaceDark: C),
  // surfaceRGB: Color(surfaceCol).rgb().array().join(','),
  surfaceShade: [
    // Color(surfaceCol).lighten(0.5).hex(),
    // Color(surfaceCol).lighten(0.25).hex(),
    // Color(surfaceCol).hex(),
    // Color(surfaceCol).darken(0.25).hex(),
    // Color(surfaceCol).darken(0.5).hex(),
  ],
  svgFill: svgFillCol,
  svgHoverFill: svgFillHoverCol,
  text: textCol,
  'text-secondary': textSecondaryCol,
  trueWhite,
  warning: warningCol,
  // trueWhiteRGB: Color(trueWhite).rgb().array().join(','),
  white: whiteCol,
  // whiteRGB: Color(whiteCol).rgb().array().join(','),
  yellow: yellowCol,
  // yellowRGB: Color(yellowCol).rgb().array().join(','),
};

export const darkModeColors = {} as any;
