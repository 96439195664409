import { captureException, captureMessage } from '@sentry/nextjs';

import { signOut } from '@/lib/auth';

import { messagesEN } from '@/lib/statusCodes/messagesEN';
import { isBrowserTrackingBlocked } from './browser';

export const TIMESLOT_BOOKED_ERROR_MESSAGE =
  'Sorry, this time is no longer available.';
export const GENERIC_ERROR_MESSAGE = 'Something went wrong!';
export const UNAUTHORIZED_ERROR_MESSAGE =
  "Sorry, you're not authorized to do this.";

export const formatErrorMessage = error => {
  // This takes in an error object and returns a readable, user-friendly and/or custom message.
  // Supposed to deal ONLY with the text message itself.
  if (!error) return `${GENERIC_ERROR_MESSAGE}`;

  // Handle string error (custom message message or generic from server)
  if (typeof error === 'string') {
    if (error.includes('generic')) {
      return GENERIC_ERROR_MESSAGE;
    }
    if (error.includes('already-booked')) {
      return TIMESLOT_BOOKED_ERROR_MESSAGE;
    }
    if (error.includes('unauthorized')) {
      return UNAUTHORIZED_ERROR_MESSAGE;
    }

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error('[DEV] in error type is string', error);
    }
    return `${GENERIC_ERROR_MESSAGE}`;
  }

  if (
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.errors &&
    error.networkError.result.errors.length
  ) {
    return error.networkError.result.errors.map((err, i) => {
      return (
        <div key={i} data-testid="graphql-error">
          {err?.extensions?.data?.map(ext => ext.messages[0].message) ||
            err.message}
        </div>
      );
    });
  }

  if (error.graphQLErrors) {
    if (error.graphQLErrors[0]?.message?.includes('User Not Found'))
      return signOut({});

    if (error.graphQLErrors[0]?.message?.includes('Forbidden'))
      return 'Sorry, looks like you are not allowed to do this.';
    if (error.graphQLErrors[0]?.message?.includes('already-booked'))
      return TIMESLOT_BOOKED_ERROR_MESSAGE;

    if (error.graphQLErrors?.length) {
      return error.graphQLErrors.map(graphQLErr => {
        const errorData =
          graphQLErr.extensions?.exception?.data || graphQLErr.message;
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error('[DEV] in error.graphQLErrors', error.graphQLErrors);
        }

        if (errorData.message) {
          return (
            errorData.message[0]?.messages[0]?.message ||
            `${GENERIC_ERROR_MESSAGE}`
          );
        }
        return `${GENERIC_ERROR_MESSAGE}`;
      });
    }

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error('[DEV] in error.graphQLErrors', error.graphQLErrors[0]);
    }
  }

  return (
    error.message?.replace('GraphQL error: ', '') ||
    'Oops! Something went wrong.'
  );
};



export const handleError = async (error, shouldReport = true) => {
  const formattedErrorMsg = formatErrorMessage(error);

  if (process.env.NODE_ENV === 'development') {
    const enrichedConsoleMsg = `[DEV] ❌ error: ${formattedErrorMsg}`;

    // eslint-disable-next-line no-console
    return console.error(enrichedConsoleMsg, error);
  }

  const trackingBlocked = await isBrowserTrackingBlocked();
  console.info(`trackingBlocked:`, trackingBlocked);

  if (shouldReport) {
    if (trackingBlocked) {
      // eslint-disable-next-line no-console
      console.error('Sentry is blocked, with error', { error });
    } else {
      captureException(error);
    }
  }

  return formattedErrorMsg;
};

export const parseErrorMessage = (errMsg: string) => {
  if (!errMsg) return {};

  const errorIntent = errMsg.split(':')[0];
  const code = errorIntent.split('(')[1]?.replace(')', '') || '0.0.0.0'; // TODO: define default code
  const [groupCode, moduleCode, subModuleCode, messageCode] = code.split('.');

  return {
    code,
    groupCode,
    message: messagesEN[code] || 'Something went wrong!',
    messageCode,
    moduleCode,
    subModuleCode,
  };
};
