import slugify from 'slugify';
import { v4 as uuidV4 } from 'uuid';

export const getSlug = (text: string, options = {}) => {
  const slug = slugify(text, {
    lower: true, // convert to lower case, defaults to `false`
    replacement: '-', // replace spaces with replacement character, defaults to `-`
    strict: true, // strip special characters except replacement, defaults to `false`
    trim: true, // trim leading and trailing replacement chars, defaults to `true`
    ...options,
  });

  return slug;
};

export const kebabToCapitalized = (kebabCaseSlug: string): string => {
  if (!kebabCaseSlug) return '';

  return kebabCaseSlug
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const uuid = uuidV4;
