import { DetailedHTMLProps, FormHTMLAttributes } from 'react';
import { useForm } from 'react-hook-form';

type FormTagProps = DetailedHTMLProps<
  FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>;

interface FormProps extends Omit<FormTagProps, 'children'> {
  children: (methods: any) => React.ReactNode;
  defaultValues?: any;
  formId?: string;
  mode?: 'onBlur' | 'onChange' | 'onSubmit';
  reValidateMode?: 'onChange' | 'onBlur' | 'onSubmit';
  onSubmit?: (data: any) => void;
}

const Form = (props: FormProps) => {
  const {
    children,
    // Once upon a time defaultValues had an empty object as a default,
    // this caused infinite loop in rerenders, when `defaultValues` were added to the useEffect dependency. - any idea why?
    // To replicate replace below line with `defaultValues = {}` and leave it in `useEffect` deps.
    // Then implement the `<Form />` without explicitly providing defaultValues.
    defaultValues,
    formId,
    mode = 'onBlur',
    reValidateMode = 'onChange',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit = () => {},
    ...rest
  } = props;

  const { handleSubmit, formState, ...methods } = useForm({
    defaultValues,
    mode,
    reValidateMode,
  });

  if (typeof children !== 'function')
    throw new Error(
      'Form children must be a function. Did you forget to use render props?'
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formId} {...rest}>
      {children({
        formState,
        handleSubmit,
        methods: { ...methods, errors: formState.errors },
      })}
    </form>
  );
};

export default Form;
