import { useCurrentUser } from '@/auth/useCurrentUser';

import { IconMenuItem } from './IconMenuItem';

const DoctorAppIconMenuItem = () => {
  const currentUser = useCurrentUser();
  const { doctorId, membershipsCount } = currentUser;

  const showDoctorApp = Boolean(doctorId || membershipsCount);
  if (!showDoctorApp) return null;

  return (
    <IconMenuItem
      icon="user-doctor"
      href={'/doctor/dashboard' as any}
      label="Doctor App"
    />
  );
};

export default DoctorAppIconMenuItem;
