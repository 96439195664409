// Code schema: 1.2.3.4
// 1 - group
// 2 - module
// 3 - sub-module
// 4 - message

// Groups:
// 1 - error (critical issue in DB, request, 3d party API)
// 2 - warning (in relation to ...?) //TODO: Review this
// 3 - exception (missed id, auth problems, etc.)
// 4 - info (is already exist, there aren't time slots, etc.)

export const messagesEN = {
  // Error
  // User Invite
  '1.1.0.1': 'Failed to create User invite',
  // Health Card
  '1.5.0.1': 'Failed to create health card',
  // Exception
  // User
  '3.2.0.1': 'User is not a doctor',
  // Info
  // User Invite
  '4.1.0.1': 'This patient has already been invited by you.',
  '4.1.0.2': 'This patient has already been invited by someone.',
  // User
  '4.2.0.1': 'User with this email exists.',
};
