import { useReactiveVar } from '@apollo/client';

import Form from '@/components/data-entry/Form';
import FullButton from '@/components/data-entry/FullButton';
import { FullTextArea } from '@/components/data-entry/FullTextArea';

import { useCreateFeedbackItemMutation } from '@/generated/graphql';

import { isFeedbackModalOpenVar } from '@/shared-state/common/feedback';

import { handleError } from '@/utils/errors';

import Modal from './Modal';
import infoToast from './utils/doNotify';

export const FeedbackModal = () => {
  const isOpen = useReactiveVar(isFeedbackModalOpenVar);

  const [createFeedbackItemMutation] = useCreateFeedbackItemMutation({
    onCompleted: () => {
      isFeedbackModalOpenVar(false);
      infoToast({
        message: `Thank you for your feedback.`,
        title: 'Message sent.',
      });
    },
    onError(error) {
      handleError(error);

      alert(
        'There was an error sending your feedback. Please try again later.'
      );
    },
  });

  const handleSubmitFeedback = async formData => {
    await createFeedbackItemMutation({
      variables: {
        summary: formData.summary,
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => isFeedbackModalOpenVar(false)}
      title="Contact Us"
    >
      <Form onSubmit={handleSubmitFeedback}>
        {({ methods }) => (
          <>
            <FullTextArea
              description="Maximum 500 characters"
              label="Kindly provide details about the issue you are facing"
              methods={methods}
              name="summary"
              required
              validate={{
                maxLength: {
                  message: 'Maximum 500 characters',
                  value: 500,
                },
              }}
            />

            <div className="flex gap-5 mt-3">
              <FullButton>Send</FullButton>
              <FullButton
                type="button"
                variant="outline"
                onClick={() => isFeedbackModalOpenVar(false)}
              >
                Cancel
              </FullButton>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};
