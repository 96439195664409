// import * as GradientColors from '@/lib/mixins/gradientColors';
import {
  bodyFontStack,
  breakpointsPxArray,
  buttonFontStack,
  captionTextFontStack,
  fancyFontStack,
  headingFontStack,
  inputFontStack,
  monoFontStack,
  sansSerifFontStack,
  serifFontStack,
} from '@/lib/constants';

import { darkModeColors, defaultColors } from '@/utils/Colors';

const skeletonTheme = {
  animation: 'pulse',
  avatarRadius: '50%',
  skeletonRadius: '16px',
};

const fontProps = {
  fontSizes: [8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 64] as any,
  fontWeights: [100, 300, 400, 500, 700, 900] as any,
  fonts: {
    fancy: fancyFontStack,
    mono: monoFontStack,
    sansSerif: sansSerifFontStack,
    serif: serifFontStack,
    // eslint-disable-next-line sort-keys
    body: bodyFontStack,
    button: buttonFontStack,
    caption: captionTextFontStack,
    h1: headingFontStack,
    h2: headingFontStack,
    h3: headingFontStack,
    h4: headingFontStack,
    h5: headingFontStack,
    heading: headingFontStack,
    input: inputFontStack,
  },
};
fontProps.fontWeights.h1 = fontProps.fontWeights[4];
fontProps.fontWeights.h2 = fontProps.fontWeights[4];
fontProps.fontWeights.h3 = fontProps.fontWeights[4];
fontProps.fontWeights.h4 = fontProps.fontWeights[4];
fontProps.fontWeights.h5 = fontProps.fontWeights[4];
fontProps.fontWeights.h6 = fontProps.fontWeights[4];
fontProps.fontWeights.caption = fontProps.fontWeights[2];

fontProps.fontSizes.body = fontProps.fontSizes[4];
fontProps.fontSizes.button = fontProps.fontSizes[4];
fontProps.fontSizes.h1 = fontProps.fontSizes[10]; // 36
fontProps.fontSizes.h2 = fontProps.fontSizes[8]; // 28
fontProps.fontSizes.h3 = fontProps.fontSizes[7]; // 24
fontProps.fontSizes.h4 = fontProps.fontSizes[6]; // 20
fontProps.fontSizes.h5 = fontProps.fontSizes[5]; // 18
fontProps.fontSizes.h6 = fontProps.fontSizes[3]; // 16
fontProps.fontSizes.caption = fontProps.fontSizes[2];
fontProps.fontSizes.eyebrow = fontProps.fontSizes[3];

const commonProperties = {
  ...fontProps,
  // @TODO: factor-out all the constants from theme
  // Radiuses
  // CARD
  breakpoints: breakpointsPxArray,
  buttonTransition: 'all 0.125s cubic-bezier(0.645, 0.045, 0.355, 1)',
  cardRadius: '0.75em',
  cardRadiusLG: '1.25em',
  cardRadiusSM: '8px',
  cardRadiusXS: '4px',
  cardRadiusXXS: '3px',
  cardShadow: '6px 10px 11px rgba(0,0,0,0.15), 0 5px 5px rgba(0,0,0,0.05)',

  letterSpacings: {
    mega: '0.25em',
    normal: 'normal',
    tight: '-0.05em',
    tracked: '0.1em',
  },
  lineHeights: {
    copy: 1.5,
    solid: 1,
    title: 1.25,
  },
};

const theme = {
  ...commonProperties,
  ...skeletonTheme,
  borders: [
    0,
    `1px solid ${defaultColors.border}`,
    `2px solid ${defaultColors.border}`,
    `4px solid ${defaultColors.border}`,
    `8px solid ${defaultColors.border}`,
    `16px solid ${defaultColors.border}`,
    `32px solid ${defaultColors.border}`,
  ],
  colors: defaultColors,
  skeletonColor: defaultColors.fadedPrimary,
  skeletonShineColor: defaultColors.secondary,
  // eslint-disable-next-line sort-keys
  name: 'light',
};

export const invTheme = {
  ...commonProperties,
  ...skeletonTheme,
  borders: [
    0,
    `1px solid ${darkModeColors.border}`,
    `2px solid ${darkModeColors.border}`,
    `4px solid ${darkModeColors.border}`,
    `8px solid ${darkModeColors.border}`,
    `16px solid ${darkModeColors.border}`,
    `32px solid ${darkModeColors.border}`,
  ],
  colors: darkModeColors,
  skeletonColor: darkModeColors.fadedPrimary,
  skeletonShineColor: darkModeColors.secondary,
  // eslint-disable-next-line sort-keys
  name: 'dark',
};

export default theme;
