var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d2463499cafd99b82c4f071a3aead1d24a13c335"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || 'https://a9a7a5e9b50f45a1ae8a82b59d406b10@o1108335.ingest.sentry.io/6135949';
const SHOULD_REPLAY = Boolean(process.env.SENTRY_REPLAYS_ON);

const NODE_ENV =  process.env.NODE_ENV !== 'development';
const VERCEL_ENV =  process.env.VERCEL_ENV || 'unknown';

const replays = SHOULD_REPLAY
  ? {
      integrations: [new Sentry.Replay()],
      replaysOnErrorSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
    }
  : {};

Sentry.init({
  ...replays,
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  enabled: NODE_ENV,
  environment: VERCEL_ENV,

  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
