import { captureMessage } from '@sentry/nextjs';
import { cloneElement, isValidElement } from 'react';

import { classNames } from '@/utils/classNames';
import { renderIcon, IconTypes } from '@/utils/icons';

export const svgStyles = {
  /* eslint-disable sort-keys */
  size: {
    base: 'h-5 w-5',
    xs: 'h-3 w-3',
    sm: 'h-4 w-4',
    md: 'h-5 w-5',
    lg: 'h-6 w-6',
    xl: 'h-10 w-10',
    '2xl': 'h-12 w-12',
    '3xl': 'h-14 w-14',
  },
  /* eslint-enable sort-keys */
};

export type SVGSizes =
  | 'base'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl';

const predefinedIconMap = {
  add: 'plus',
  author: 'feather-alt',
  authoring: 'feather-alt',
  blog: 'newspaper',
  cancel: 'times',
  course: 'graduation-cap',
  create: 'plus',
  dashboard: 'rectangles-mixed',
  delete: 'trash',
  drag: 'grip',
  email: 'envelope',
  explore: 'compass',
  home: 'house-blank',
  left: 'chevron-left',
  like: 'heart',
  linkedin: 'linkedin-in',
  loading: 'circle-notch',
  moon: 'moon-stars',
  'new-window': 'arrow-up-right-from-square',
  post: 'newspaper',
  preview: 'eye',
  right: 'chevron-right',
  'sign-in': 'person-to-portal',
  'sign-out': 'right-from-bracket',
  'sort-alpha-asc': 'arrow-up-a-z',
  'sort-alpha-desc': 'arrow-down-a-z',
  'sort-num-asc': 'arrow-up-9-1',
  'sort-num-desc': 'arrow-up-1-9',
  vimeo: 'vimeo-v',
};

export type PredefinedIconsTypes = IconTypes | keyof typeof predefinedIconMap;
export interface SVGProps {
  alt?: string;
  auxClassNames?: string;
  className?: string;
  fillClassName?: string;
  sizeClassName?: string;
  /** Add extra styling that does not relate to act */
  dataTest?: string;
  icon: PredefinedIconsTypes | JSX.Element;
  loading?: boolean;
  size?: SVGSizes;
  title?: string;
}

const predefinedFillClx = {
  osi: 'fill-osi',
};

const SVG = ({
  auxClassNames,
  className,
  fillClassName,
  sizeClassName,
  icon: providedIcon,
  loading,
  size,
  ...rest
}: SVGProps): JSX.Element | null => {
  const isLoading = loading || providedIcon === 'loading';
  const icon = loading
    ? 'circle-notch'
    : (typeof providedIcon === 'string' && predefinedIconMap[providedIcon]) ||
      providedIcon;

  const normalizedFillClx =
    fillClassName || predefinedFillClx[icon] || 'fill-current';

  const normalizedClassName = classNames(
    isLoading ? 'animate-spin' : '',
    !sizeClassName && svgStyles.size[size || 'base'],
    sizeClassName,
    auxClassNames,
    icon?.props?.className || '',
    normalizedFillClx,
    className || ''
  );

  if (typeof icon === 'string') {
    const FormattedIcon = renderIcon(icon);

    return (
      <FormattedIcon
        style={!size ? { minHeight: 6, minWidth: 6 } : undefined}
        className={normalizedClassName}
        {...rest}
      />
    );
  }

  const isValid = isValidElement(icon);
  if (!isValid) {
    // eslint-disable-next-line no-console
    console.error(`Invalid SVG element, ${icon}`);
    captureMessage(`SVG icon is not a valid React element. Icon: ${icon}`);
  }

  const IconClone = cloneElement(icon, {
    ...rest,
    className: normalizedClassName,
  });
  return IconClone;
};

export default SVG;
