import { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';

const FeedbackProvider = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <ToastContainer autoClose={4500} />
      {children}
    </>
  );
};

export default FeedbackProvider;
