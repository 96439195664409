import Head from 'next/head';

const Meta = () => {
  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <meta charSet="utf-8" />
      {/* @TODO: Add favicon */}
      {/* <link rel="shortcut icon" href="/favicon.png" /> */}
      <style>
        {` 
            @font-face {
              font-family: 'Open Sans', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/raleway/v17/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCIPrcVIT9d0c8.woff) format('woff');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
           `}
      </style>
    </Head>
  );
};

export default Meta;
