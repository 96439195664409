import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { HTMLProps, ReactNode, useEffect } from 'react';

import {
  inputBorderWidth,
  inputHeightClassLg,
  inputHeightClassMd,
  inputHeightClassSm,
  inputHeightClassXl,
  inputHeightClassXs,
  inputRingWidth,
} from '@/lib/constants';

import { Size } from '@/types';

import { classNames } from '@/utils/classNames';
import { email as isEmail } from '@/utils/formValidations';
import { uuid } from '@/utils/text';

import InputFeedback from './InputFeedback';
import { InputLabel } from './InputLabel';

export const inputClassNames = {
  default: {},
  /* eslint-disable sort-keys */
  transition: 'transition-all duration-200',
  core: 'w-full',
  // Variants + ColorSchemes
  outline: {
    shadow: 'hover:shadow-md',
    default: {
      bgColor: 'bg-surface',
      border: `${inputBorderWidth} border-border focus:border-primary focus:outline-primary`,
      value: 'text-text',
      description: 'mt-1 text-gray-500 text-xs',
      label: 'text-text font-normal whitespace-nowrap text-sm',
    },
    inactive: {
      bgColor: 'bg-gray-200 cursor-not-allowed',
      border: `${inputBorderWidth} border-gray-400`,
      value: 'text-gray-500',
      description: 'mt-1 text-gray-500 text-xs',
      label: 'text-gray-500 font-normal whitespace-nowrap text-sm',
    },
    invalid: {
      bgColor: 'bg-surface',
      border: `${inputBorderWidth} border-red-200 focus:border-error`,
      value: 'text-red-500',
      description: 'mt-1 text-error text-xs',
      label: 'text-error font-normal whitespace-nowrap text-sm',
    },
  },

  ring: `${inputRingWidth} focus:ring-primary ring-opacity-0 focus:ring-opacity-100 focus:ring-offset-background`,

  base: {
    height: inputHeightClassMd,
    spacing: `rounded px-4 py-2`,
  },
  xs: {
    height: inputHeightClassXs,
    spacing: `rounded px-2 py-0 text-xs`,
  },
  sm: {
    height: inputHeightClassSm,
    spacing: `rounded px-2 py-0 text-sm`,
  },
  md: {
    height: inputHeightClassMd,
    spacing: `rounded px-4 py-2`,
  },
  lg: {
    height: inputHeightClassLg,
    spacing: `rounded px-5 py-3`,
  },
  xl: {
    height: inputHeightClassXl,
    spacing: `rounded px-8 py-4`,
  },
  /* eslint-enable sort-keys */
};

inputClassNames.default = inputClassNames.outline;

export interface FullInputProps
  extends Omit<HTMLProps<HTMLInputElement>, 'size'> {
  autoComplete?: string;
  block?: boolean;
  className?: string;
  colorScheme?: 'default' | 'inactive' | 'invalid';
  customValidate?: any;
  description?: string;
  disabled?: boolean;
  error?: string;
  id?: string;
  inputClassName?: string;
  label?: string;
  labelExtra?: string;
  labelTooltipContent?: ReactNode;
  labelTooltipOptions?: any;
  labelTooltipTrigger?: ReactNode;
  max?: number;
  maxLength?: number;
  methods?: any;
  min?: number;
  name?: string;
  onChange?: (e: any) => void; // TODO: Room for improvement
  orientation?: 'horizontal' | 'horizontal-reverse' | 'vertical';
  prefix?: string;
  prefixPadding?: string;
  required?: boolean;
  shouldTrimOnBlur?: boolean;
  shouldUpperCaseInput?: boolean;
  shouldLowerCaseInput?: boolean;
  shouldUnregister?: boolean;
  size?: Size;
  suffix?: string;
  type?: string;
  validate?: any;
  variant?: 'outline';
}

const FullInput = ({
  // id,
  // onSave,
  block,
  className,
  colorScheme = 'default',
  customValidate = {},
  description,
  disabled,
  error,
  id,
  inputClassName,
  label,
  labelExtra = '',
  labelTooltipContent,
  labelTooltipOptions,
  labelTooltipTrigger,
  methods,
  name,
  orientation,
  prefix,
  prefixPadding = 'pl-[72px]',
  required,
  shouldTrimOnBlur = false,
  shouldUpperCaseInput,
  shouldLowerCaseInput,
  shouldUnregister,
  size = 'base',
  suffix,
  type,
  validate,
  variant = 'outline',
  ...rest
}: FullInputProps) => {
  if (methods && !name) {
    throw new Error('FullInput: name is required if methods is provided');
  }

  // Enables us to simply provide "required" prop where component is used
  const withRequiredValidation = required
    ? { required: 'This field is required.' }
    : {};
  const withEmailValidation = type === 'email' ? { isEmail } : {};

  const fieldValidation = {
    ...withRequiredValidation,
    ...withEmailValidation,
    ...validate,
    validate: customValidate,
  };

  const errorMessage = methods
    ? methods.errors[name as string]?.message
    : error;

  const invalid = !!errorMessage;

  const coreProps = inputClassNames.core;
  const transitionProps = inputClassNames.transition;

  const shadowStyles = inputClassNames[variant]?.shadow;
  const inputSpacingStyles = inputClassNames[size].spacing;
  const inputHeightStyles = inputClassNames[size].height;

  const ringStyles = inputClassNames.ring;
  const normalizedColorScheme = disabled
    ? 'inactive'
    : invalid
    ? 'invalid'
    : colorScheme;

  const bgColorStyles =
    inputClassNames[variant]?.[normalizedColorScheme]?.bgColor;
  const borderStyles =
    inputClassNames[variant]?.[normalizedColorScheme]?.border;
  const valueStyles = inputClassNames[variant]?.[normalizedColorScheme]?.value;

  const layoutClassNames = classNames(
    block && 'flex-1 w-full',
    orientation === 'horizontal-reverse'
      ? 'flex gap-2 items-center flex-row-reverse'
      : orientation === 'horizontal' && 'flex gap-2 items-center',
    className
  );

  useEffect(() => {
    if (shouldUnregister) {
      methods?.unregister(name);
    }
  }, [required]);

  const methodsRegisterProps = methods?.register(name, fieldValidation) || {};

  const inputControlProps = {
    ...methodsRegisterProps,
    ...rest,
  };

  inputControlProps.onChange = (e: any) => {
    let { value } = e.target;

    if (shouldUpperCaseInput) {
      value = value.toUpperCase();
    }

    if (shouldLowerCaseInput) {
      value = value.toLowerCase();
    }

    e.target.value = value;

    methodsRegisterProps.onChange?.(e);
    rest.onChange?.(e);
  };

  inputControlProps.onBlur = (e: any) => {
    let { value } = e.target;

    if (shouldTrimOnBlur) {
      value = value.trim();
    }

    e.target.value = value;

    methodsRegisterProps.onBlur?.(e);
    rest.onBlur?.(e);
  };

  const inputId = id || name || uuid();

  return (
    <div className={layoutClassNames}>
      <InputLabel
        colorScheme={normalizedColorScheme}
        content={label}
        extraContent={labelExtra}
        inputId={inputId}
        tooltipContent={labelTooltipContent}
        tooltipOptions={labelTooltipOptions}
        tooltipTrigger={labelTooltipTrigger}
        variant={variant}
      />

      <div
        className={classNames(
          'relative rounded-xl',
          shadowStyles,
          transitionProps,
          orientation !== 'horizontal' && ' mt-0'
        )}
      >
        {Boolean(prefix) && (
          <div
            className={classNames(
              'absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'
            )}
          >
            <span className="text-gray-500 sm:text-sm">{prefix}</span>
          </div>
        )}
        <input
          aria-invalid={invalid ? 'true' : undefined}
          className={classNames(
            bgColorStyles,
            borderStyles,
            ringStyles,
            coreProps,
            inputHeightStyles,
            inputSpacingStyles,
            valueStyles,
            transitionProps,
            prefix && prefixPadding,
            suffix && 'pr-14',
            inputClassName
          )}
          disabled={disabled}
          type={type}
          {...inputControlProps}
          id={inputId}
        />

        {suffix && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="font-bold sm:text-sm" id="price-currency">
              {suffix}
            </span>
          </div>
        )}

        {invalid && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>

      {(errorMessage || description) && (
        <InputFeedback error={errorMessage} description={description} />
      )}
    </div>
  );
};

export default FullInput;
