import { ErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from '@/components/feedback/ErrorFallback';

function fallbackRender(props) {
  return <ErrorFallback {...props} />;
}

export const AppErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundary fallbackRender={fallbackRender}>{children}</ErrorBoundary>
  );
};
