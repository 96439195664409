import { calendarStyles } from 'flyui-kit';
import { createGlobalStyle } from 'styled-components';

import {
  breakpointQueries,
  bodyFontStack,
  fancyFontStack,
  serifFontStack,
} from '@/lib/constants';

import {
  accentCol,
  backgroundCol,
  backgroundSecondaryCol,
  borderCol,
  errorCol,
  infoCol,
  neutralCol,
  primary9Col,
  primaryCol,
  primaryLightCol,
  secondaryCol,
  successCol,
  surfaceCol,
  surfaceNeutralCol,
  surfaceSecondaryCol,
  textSecondaryCol,
  warningCol,
} from '@/utils/Colors';

const GlobalStyle = createGlobalStyle` 
    ${calendarStyles};

    /* CSS RESET */
    *, *::before, *::after {
        box-sizing: border-box;
    }
    html, body, #__next {
        height: 100%;
    }
    body {
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
    }
    img, picture, video, canvas, svg {
        display: block;
        max-width: 100%;
    }
    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
    }
    #root, #__next {
        /* When this is enabled, stacking with zIndices breaks */
        /* Needs more investigation on whether this will be useful. */
        /* isolation: isolate; */
    }
    /* _____ END CSS RESET */

    /* @TODO: @ScottAgirs - Remove these global styles in favor of typography. */
    h1, h2, h3, h4, h5, h6 {
        font-family: ${serifFontStack};
    }

    :root {
        /* Custom TW Extensions */
        --color-border: ${borderCol.light};
        --color-border-hover: 160, 160, 160;
        --color-border-focus: ${primaryCol.light};
        --color-background: ${backgroundCol.light};
        --color-background-secondary: ${backgroundSecondaryCol.light};
        --color-surface: ${surfaceCol.light};
        --color-surface-neutral: ${surfaceNeutralCol.light};
        --color-surface-secondary: ${surfaceSecondaryCol.light};
        --color-primary: ${primaryCol.light};
        --color-text-secondary: ${textSecondaryCol.light};
        --color-error: ${errorCol.light};
        --color-info: ${infoCol.light};
        --color-success: ${successCol.light};
        --color-primary-light: ${primaryLightCol.light};
        --color-primary-9: ${primary9Col.light};
        --color-secondary: ${secondaryCol.light};
        --color-accent: ${accentCol.light};
        --color-neutral: ${neutralCol.light};
        --color-warning: ${warningCol.light};
    }

    html {
        background-color: rgb(var(--color-background));
        color: rgb(var(--color-primary));
    }

    html,
    body {
        font-size: 16px;
        font-family: ${bodyFontStack};
        letter-spacing: -0.005em;
        margin: 0;
        padding: 0;
        
    }

    strong { 
        font-weight: 600;
    }

    textarea {
        resize: vertical;
    }

    /* @TODO: @ScottAgirs - Remove when FancyText is implemented. */
    /* Issue: https://github.com/PocketMD/pocketmd-web-fe/issues/9 */
    .fancy-typed {
        font-family: ${fancyFontStack};
    }

    .spin {
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        animation: spin 1.125s infinite linear;
    }

    .Toastify__toast {
        border-radius: 4px !important;
        margin: 1rem;
        ${breakpointQueries.mobile} {
            margin: 0 0 1rem;
        }
    }
    .Toastify__toast-body {
        padding: 0 0.75rem 0.5rem;
    }
    
    .Toastify__toast--warning {
        background: ${({ theme }) => theme.alertCol} !important;
    }
    .Toastify__toast--error {
        background: ${({ theme }) => theme.errorCol} !important;
    }
    .Toastify__toast--info {
        background: ${({ theme }) => theme.secondaryCol} !important;
    }

`;

export default GlobalStyle;
