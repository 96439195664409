export const classNames = (
  ...classes: Array<string | boolean | undefined | null | void>
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const classStrings = classes.filter(Boolean).map(c => c.split(' '));

  const individualClasses = [];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  classStrings.forEach(c => c.forEach(s => individualClasses.push(s)));

  const uniqClasses = [...new Set(individualClasses)];

  return uniqClasses.filter(Boolean).join(' ');
};
