import { classNames } from '@/utils/classNames';

import { inputClassNames } from './FullInput';

const InputFeedback = ({
  colorScheme = 'default',
  dataTestId = 'input-feedback',
  description,
  disabled,
  error,
  required,
  variant = 'default',
  ...rest
}: any) => {
  const invalid = !!error;

  const normalizedColorScheme = disabled
    ? 'inactive'
    : invalid
    ? 'invalid'
    : colorScheme;

  const descriptionStyles =
    inputClassNames[variant]?.[normalizedColorScheme]?.description;

  return (
    <div
      data-testid={dataTestId}
      className={classNames('mt-1', descriptionStyles)}
      {...rest}
    >
      {error || description || (required && 'This field is required.')}
    </div>
  );
};

export default InputFeedback;
