import { ReactNode, lazy, Suspense } from 'react';

import { a11yClick } from '@/utils/a11y';
import { classNames } from '@/utils/classNames';

import { inputClassNames } from './FullInput';

const Tooltip = lazy(() =>
  import('@/components/feedback/Tooltip').then(module => ({
    default: module.Tooltip,
  }))
);

interface InputLabelProps {
  className?: string;
  colorScheme?: string;
  content?: ReactNode;
  extraContent?: ReactNode;
  inputId?: string;
  onClick?: () => void;
  tooltipClassName?: string;
  tooltipContent?: ReactNode;
  tooltipOptions?: Record<string, any>;
  tooltipTrigger?: ReactNode;
  variant?: string;
}

export const InputLabel = ({
  className,
  colorScheme = 'default',
  content,
  extraContent,
  inputId,
  onClick,
  tooltipClassName,
  tooltipContent,
  tooltipOptions,
  tooltipTrigger,
  variant = 'outline',
}: InputLabelProps) => {
  const labelStyles =
    className || inputClassNames[variant]?.[colorScheme]?.label;

  return (
    <div className={classNames('flex justify-between')}>
      <label
        htmlFor={inputId}
        className={classNames('flex gap-1', labelStyles)}
        onClick={onClick}
        onKeyDown={onClick ? e => a11yClick(e, onClick) : undefined}
      >
        {tooltipContent && !tooltipTrigger ? null : <span>{content}</span>}

        {tooltipContent && (
          <Suspense fallback={null}>
            <Tooltip
              className={tooltipClassName}
              content={tooltipContent}
              options={tooltipOptions}
              trigger={tooltipTrigger || content}
            />
          </Suspense>
        )}
      </label>

      {extraContent && (
        <span className="text-sm text-gray-500">{extraContent}</span>
      )}
    </div>
  );
};
