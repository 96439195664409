export const isDev = process.env.NODE_ENV === `development`;

const httpProtocol = process.env.NODE_ENV === `development` ? `http` : `https`;
const wsProtocol = process.env.NODE_ENV === `development` ? `ws` : `wss`;

const CLIENT_BASE_URL = isDev
  ? `localhost:3333`
  : process.env.NEXT_PUBLIC_CLIENT_URL?.replace(/https?:\/\//, ``);
const BACKEND_BASE_URL = isDev
  ? `localhost:3000`
  : process.env.NEXT_PUBLIC_BACKEND_URL?.replace(/https?:\/\//, ``);

export const GRAPHQL_URL = `${httpProtocol}://${CLIENT_BASE_URL}/api/graphql`;
export const GRAPHQL_URL_WS = `${wsProtocol}://${BACKEND_BASE_URL}/sub`;

// TODO: Move to env
export const GOOGLE_PLACES_KEY = `AIzaSyC8tCcBm-swCnqAg207cysIUfI8sV-th2c`;
