import moment from 'moment';
import PasswordValidator from 'password-validator';
import isEmail from 'validator/lib/isEmail';

const schema = new PasswordValidator();
schema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(48) // Maximum length 100
  .has()
  .letters() // Must have uppercase letters
  .has()
  .digits(); // Must have digits.

export const requiredCheckbox = value =>
  value !== undefined &&
  value !== null &&
  value !== '' &&
  value !== false &&
  !(Array.isArray(value) && value.length === 0)
    ? undefined
    : 'Required';

export const email = value =>
  value && !isEmail(value) ? `Email appears to be invalid` : undefined;

export const required = value => {
  if (typeof value === 'undefined' || value === null || value === '')
    return 'Required';
  return undefined;
};

export const maxLength = max => value => {
  if (typeof value === 'undefined' || value === null) return undefined;
  return value.length > max ? `Must be ${max} characters or less` : undefined;
};

export const exactLength = length => value => {
  if (typeof value === 'undefined' || value === null) return undefined;
  return value.toString().length !== length
    ? `Must be exactly ${length} characters`
    : undefined;
};

export const maxLength15 = maxLength(15);

export const number = value => {
  if (typeof value === 'undefined' || value === null) return undefined;

  return isNaN(parseInt(value, 10)) ? 'Must be a number' : undefined;
};

export const phoneNumber = value => {
  if (typeof value === 'undefined' || value === null) return undefined;
  const regExp = /^\+1[2-9]([0-9]{9})$/;
  return !regExp.test(value) ? 'Phone number is not valid' : undefined;
};

export const invalidVersionCode = value => {
  if (!value) return undefined;
  const regex = /^[A-Z]{2}$/;
  return !regex.test(value) ? 'Version code must be 2 letters' : undefined;
};

export const invalidFutureTime = value => {
  if (typeof value === "undefined" || value === null) return undefined;
  
  const inputDate = moment(value, 'YYYY-MM-DD', true).isValid() ? moment(value).endOf('day') : moment(value);

  return inputDate.isBefore(moment())
    ? 'Date must be in the future'
    : undefined;
};

export const invalidFutureDate = value => {
  if (typeof value === 'undefined' || value === null) return undefined;
  return moment(value).isBefore(moment())
    ? 'Date must be in the future'
    : undefined;
};

export const invalidPastDate = value => {
  if (typeof value === 'undefined' || value === null) return undefined;
  return moment(value).isAfter(moment())
    ? 'Date must be in the past'
    : undefined;
};

export const ohipBillingCodeGroup = value => {
  if (!value) return undefined;
  const regex = /^\d{4}$/;
  return !regex.test(value) ? 'Group code must be 4 digits' : undefined;
};

export const ohipBillingCodePractitionerNumber = value => {
  if (!value) return undefined;
  const regex = /^\d{5,6}$/;
  return !regex.test(value)
    ? 'Practitioner number must be 5 or 6 digits'
    : undefined;
};

export const ohipBillingCodeSpecialty = value => {
  if (!value) return undefined;
  const regex = /^\d{2}$/;
  return !regex.test(value) ? 'Specialty code must be 2 digits' : undefined;
};

export const luCodeNumber = value => {
  if (!value) return undefined;
  const regex = /^\d{3}$/;
  return !regex.test(value) ? 'LU code must be 3 digits' : undefined;
};

export const medicalLiabilityInsuranceNumber = value => {
  if (!value) return undefined;
  const regex = /^\d{9}$/;
  return !regex.test(value)
    ? 'Medical Liability Insurance Number must be 9 digits'
    : undefined;
};

// validator for blood pressure (mmHg)
export const bloodPressure = value => {
  if (!value) return undefined;
  const regex = /^\d{2,3}\/\d{2,3}$/;
  return !regex.test(value)
    ? 'Blood pressure must be in format 120/80'
    : undefined;
};
