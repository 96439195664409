export const a11yClick = (event: any, action: () => void) => {
  if (typeof action !== 'function') return null;

  if (event.type === 'click') {
    action();
    return true;
  }

  const { key } = event as KeyboardEvent;

  if (key === 'Enter' || key === ' ') {
    action();
    return true;
  }

  return false;
};
