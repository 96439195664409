import LogoFull from '@/public/images/pocketmd-logo-full-v2.svg';

import SmartLink from './SmartLink';

const FullLogoLink = ({
  href = '/',
  removeLink,
}: {
  href?: string;
  removeLink?: boolean;
}) => {
  const Wrapper = removeLink ? 'span' : SmartLink;
  const props = removeLink ? {} : { href };

  return (
    <Wrapper {...props}>
      <LogoFull className="h-14" />
    </Wrapper>
  );
};

export default FullLogoLink;
