/* eslint-disable sort-keys */
import { classNames } from 'primereact/utils';

export const CustomTheme = {
  inputtext: {
    root: 'transition-all duration-200',
  },
  calendar: {
    day: 'p-1',
    group:
      'bg-surface flex-1 border-l border-gray-300 pr-0.5 pl-0.5 pt-0 pb-0 first:pl-0 first:border-l-0 rounded',
    input: {
      root: 'px-4 py-2 ring-0 focus:ring-1 focus:ring-offset-1 focus:outline-none ring-primary ring-opacity-0 focus:ring-opacity-100 focus:ring-offset-background hover:shadow-md border-border focus:border-primary focus:outline-primary rounded',
    },
    panel: 'bg-surface shadow-md absolute rounded',
    decadetitle: 'text-primary',
    previousButton: {
      className: classNames(
        'flex items-center justify-center cursor-pointer overflow-hidden relative',
        'w-8 h-8 text-primary border-0 bg-transparent rounded-full transition-all duration-200',
        'hover:bg-primary/25'
      ),
    },
    nextButton: {
      className: classNames(
        'flex items-center justify-center cursor-pointer overflow-hidden relative',
        'w-8 h-8 text-primary border-0 bg-transparent rounded-full transition-all duration-200',
        'hover:bg-primary/25'
      ),
    },
    monthTitle: {
      className: classNames(
        'text-primary transition-all duration-200 font-semibold py-1 px-2 rounded',
        'ring-0 focus:ring-1 focus:ring-offset-1 focus:outline-none ring-primary ring-opacity-0 focus:ring-opacity-100 focus:ring-offset-background focus:outline-primary',
        'mr-2',
        'hover:bg-primary/25'
      ),
    },
    yearTitle: {
      className: classNames(
        'text-primary transition-all duration-200 font-semibold py-1 px-2 rounded',
        'ring-0 focus:ring-1 focus:ring-offset-1 focus:outline-none ring-primary ring-opacity-0 focus:ring-opacity-100 focus:ring-offset-background focus:outline-primary',
        'hover:bg-primary/25'
      ),
    },
    yearpicker: 'p-4 flex gap-2 flex-wrap justify-center',
    year: ({ context }) => {
      return {
        className: classNames(
          'w-1/3 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
          'p-2 transition-all duration-200 rounded-lg',
          'ring-0 focus:ring-1 focus:ring-offset-1 focus:outline-none ring-primary ring-opacity-0 focus:ring-opacity-100 focus:ring-offset-background focus:outline-primary',
          {
            'text-primary hover:bg-primary/25':
              !context.selected && !context.disabled,
            'text-surface bg-primary hover:bg-primary/75':
              context.selected && !context.disabled,
          }
        ),
      };
    },
    monthpicker: 'p-4 flex gap-2 flex-wrap justify-center',
    month: ({ context }) => {
      return {
        className: classNames(
          'w-1/3 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
          'p-2 transition-all duration-200 rounded',
          'ring-0 focus:ring-1 focus:ring-offset-1 focus:outline-none ring-primary ring-opacity-0 focus:ring-opacity-100 focus:ring-offset-background focus:outline-primary',
          {
            'text-primary hover:bg-primary/25':
              !context.selected && !context.disabled,
            'text-surface bg-primary hover:bg-primary/75':
              context.selected && !context.disabled,
          }
        ),
      };
    },
    daylabel: ({ context }) => {
      return {
        className: classNames(
          'w-10 h-10 rounded-full transition-all duration-200 border-transparent border',
          'flex items-center justify-center mx-auto overflow-hidden relative',
          'ring-0 focus:ring-1 focus:ring-offset-1 focus:outline-none ring-primary ring-opacity-0 focus:ring-opacity-100 focus:ring-offset-background focus:outline-primary',
          {
            'cursor-pointer': !context.disabled,
            'text-gray-400 opacity-70 cursor-default': context.disabled,
          },
          {
            'text-primary hover:bg-primary/25':
              !context.selected && !context.disabled,
            'text-surface bg-primary hover:bg-primary/75':
              context.selected && !context.disabled,
          }
        ),
      };
    },
  },
};
