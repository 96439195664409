import Link from 'next/link';

import { LinkHTMLAttributes } from 'react';

import { classNames } from '@/utils/classNames';

export interface SmartLinkProps
  extends Partial<LinkHTMLAttributes<HTMLAnchorElement | HTMLButtonElement>> {
  target?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

const SmartLink = ({
  children,
  className: providedClassName,
  disabled,
  href,
  onClick,
  target,
  type,
  ...rest
}: SmartLinkProps) => {
  const className = classNames(
    'transition-all duration-100 rounded',
    'ring-primary ring-0 ring-offset-0 ring-opacity-0',
    providedClassName
  );

  if (onClick) {
    return (
      <button
        disabled={disabled}
        // onKeyDown={e => a11yClick(e, () => onClick())}
        // tabIndex="0"
        onClick={onClick}
        type={type}
        // role="button"
        {...rest}
        className={className}
      >
        {children}
      </button>
    );
  }

  if (!href && !onClick) {
    return (
      <a {...rest} className={className}>
        {children}
      </a>
    );
  }

  if (target === '_blank')
    return (
      <a
        href={disabled ? '' : href}
        rel="noopener noreferrer"
        tabIndex={0}
        target={target}
        {...rest}
        className={classNames(className, 'inline-flex items-center gap-1')}
      >
        {children}
      </a>
    );

  return (
    <Link href={href as string} {...rest} className={className}>
      {children}
    </Link>
  );
};

export default SmartLink;
