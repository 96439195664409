import { FlyGlobalStyle, FlyThemeProvider } from 'flyui-kit';
import { ReactNode } from 'react';

import { SkeletonThemeProvider } from 'react-skeleton';
import { ThemeProvider } from 'styled-components';

import FeedbackProvider from '@/components/common/FeedbackProvider';
import Meta from '@/components/common/Meta';
import GlobalStyle from '@/lib/GlobalStyle';

// import theme, { invTheme } from '@/lib/theme';
import theme from '@/lib/theme';

const finalTheme = theme;

const WithThemeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <ThemeProvider theme={finalTheme}>
      <FlyThemeProvider theme={finalTheme}>
        <SkeletonThemeProvider theme={finalTheme}>
          <FeedbackProvider>
            <Meta />
            {children}
            <FlyGlobalStyle />
            <GlobalStyle />
          </FeedbackProvider>
        </SkeletonThemeProvider>
      </FlyThemeProvider>
    </ThemeProvider>
  );
};

export default WithThemeProvider;
