import { useCurrentUser } from '@/auth/useCurrentUser';

import Text from '@/components/data-display/Text';

import SmartLink from './SmartLink';

export const PatientSidebarMiscLink = () => {
  const currentUser = useCurrentUser();

  return (
    <>
      {/* <Text block className="pb-2" variant="h6">
        About PocketMD
      </Text> */}

      {/* <Text pb={1} variant="caption">
        Contact PocketMD
      </Text> */}
      {/* <Text pb={1} variant="caption">
        Share
      </Text>
      <Text pb={1} variant="caption">
        Partners
      </Text> */}
      {!currentUser?.doctor?.isCompleteProfile && (
        <Text block className="pb-1 px-2">
          <SmartLink
            data-testid="link-onboard-doc"
            href="/onboard/doctor"
            className="font-bold"
          >
            Register as Doctor
          </SmartLink>
        </Text>
      )}

      {/* <Text className="pb-1">
        <SmartLink href="/help">Help Center</SmartLink>
      </Text> */}
    </>
  );
};
