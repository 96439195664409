import '../styles/global.css';
import { ApolloProvider } from '@apollo/client';
import { Analytics } from '@vercel/analytics/react';

// import Cookies from 'js-cookie';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { PagesProgressBar as ProgressBar } from 'next-nprogress-bar';

import { PrimeReactProvider } from 'primereact/api';
import { usePassThrough } from 'primereact/passthrough';
import Tailwind from 'primereact/passthrough/tailwind';

// import { useState } from 'react';

// import FullInput from '@/components/data-entry/FullInput';
// import { Confirm } from '@/components/feedback/Confirm';
import { Fragment } from 'react';

import { FeedbackModal } from '@/components/feedback/FeedbackModal';
import LoadingSpinner from '@/components/feedback/LoadingSpinner';
import { UnavailableModal } from '@/components/feedback/UnavailableModal';
import { RouterContext } from '@/context';

import { DefaultLayout } from '@/layouts/DefaultLayout';

import { DoctorMobileUnavailableLayout } from '@/layouts/DoctorMobileUnavailableLayout';
import { useApollo } from '@/lib/apolloClient';
import { AppErrorBoundary } from '@/lib/AppErrorBoundary';
import { SessionProvider } from '@/lib/auth';
import { CustomTheme } from '@/lib/CustomTheme';
import GlobalErrorBoundary from '@/lib/GlobalErrorBoundary';
import { WithAccessControl } from '@/lib/WithAccessControl';
import WithPageProps from '@/lib/WithPageProps';

const UserPrompts = dynamic(() => import('@/components/auth/UserPrompts'), {
  ssr: false,
});

const App = ({ Component, pageProps }) => {
  const apolloClient = useApollo(pageProps);
  const isTempDoctorMobileDisabled = Component.shouldPreventMobileAccess;
  const InterceptLayout = isTempDoctorMobileDisabled
    ? DoctorMobileUnavailableLayout
    : Fragment;
  const Layout = Component.Layout || DefaultLayout;
  const router = useRouter();

  // const fullHost = typeof window !== 'undefined' ? window.location.host : '';
  //
  // const isStaging = fullHost.includes('staging.');
  // const isStagingAccessAllowedCookie = Cookies.get('isStagingAccessAllowed');

  // const [stagingKey, setStagingKey] = useState('');

  // const handleConfirmStagingAccess = () => {
  //   if (stagingKey === process.env.NEXT_PUBLIC_STAGING_SEMI_SECRET) {
  //     Cookies.set('isStagingAccessAllowed', true);
  //     window.location.reload();
  //   } else {
  //     // eslint-disable-next-line no-alert
  //     alert('Invalid staging key');
  //   }
  // };

  // if (isStaging && !isStagingAccessAllowedCookie) {
  //   return (
  //     <Confirm
  //       cancelLabel="I need access"
  //       onConfirm={handleConfirmStagingAccess}
  //       // eslint-disable-next-line no-alert
  //       onClose={() => alert('Visit app.pocketmd.ca to access the app.')}
  //       isOpen
  //     >
  //       <FullInput
  //         value={stagingKey}
  //         onChange={e => setStagingKey(e.target.value)}
  //         placeholder="Enter staging key"
  //       />
  //     </Confirm>
  //   );
  // }

  const CustomTailwindTheme = usePassThrough(Tailwind, CustomTheme, {
    mergeProps: false,
    mergeSections: true,
  });

  return (
    <PrimeReactProvider value={{ pt: CustomTailwindTheme, unstyled: true }}>
      <GlobalErrorBoundary>
        <SessionProvider session={pageProps.session} refetchInterval={0}>
          <ApolloProvider client={apolloClient}>
            <UnavailableModal />

            <WithPageProps>
              <RouterContext.Provider value={router}>
                <WithAccessControl router={router}>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <UserPrompts>
                    {({ /* prompts, */ currentUser, loading }) => {
                      if (loading) return <LoadingSpinner />;

                      const serverError =
                        currentUser?.error?.networkError?.statusCode >= 500;

                      if (
                        !serverError &&
                        !currentUser.isLoggedIn &&
                        !router.asPath.includes('/login') &&
                        !router.asPath.includes('/unavailable')
                      ) {
                        router.push('/login');
                        return <LoadingSpinner />;
                      }

                      if (
                        !serverError &&
                        currentUser?.id &&
                        !currentUser.isOnboarded &&
                        !router.asPath.includes('/onboard/patient')
                      ) {
                        router.push('/onboard/patient');
                        return <LoadingSpinner />;
                      }

                      if (
                        !serverError &&
                        currentUser?.id &&
                        !currentUser.isOnboardedDoctor &&
                        !currentUser.membershipsCount &&
                        (router.asPath.startsWith('/doctor/') ||
                          router.asPath.includes('/d/'))
                      ) {
                        router.push('/onboard/doctor');
                        return <LoadingSpinner />;
                      }

                      // const userPrompt = prompts?.length && prompts[0];

                      // @TODO: Handle non-redirect prompts
                      // if (
                      //   userPrompt?.promptValue &&
                      //   !router.asPath.includes(userPrompt.promptValue)
                      // ) {
                      //   router.push(userPrompt.promptValue);

                      //   // This loading spinner ensures that there's a visual loading feedback
                      //   // while the router is redirecting (in case it's slow).
                      //   return <FullScreenLoading />;
                      // }

                      return (
                        <Layout>
                          <FeedbackModal />

                          <AppErrorBoundary>
                            <InterceptLayout>
                              <Component {...pageProps} />
                              <ProgressBar
                                height="4px"
                                color="#37585e"
                                options={{ showSpinner: false }}
                                shallowRouting
                              />
                              <Analytics />
                            </InterceptLayout>
                          </AppErrorBoundary>
                        </Layout>
                      );
                    }}
                  </UserPrompts>
                </WithAccessControl>
              </RouterContext.Provider>
            </WithPageProps>
          </ApolloProvider>
        </SessionProvider>
      </GlobalErrorBoundary>
    </PrimeReactProvider>
  );
};

export default App;
