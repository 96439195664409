import Cookies from 'js-cookie';
import {
  SessionProvider as NextAuthSessionProvider,
  signOut as nextAuthSignOut,
  signIn as nextAuthSignIn,
  useSession as nextAuthUseSession,
} from 'next-auth/react';

export const setToken = async user => {
  if (typeof window === 'undefined') return;
  const returnTo = Cookies.get('returnTo');

  if (user.isAdmin) {
    // TODO: Add logging. It is important to know when an Admin user logs in.
    Cookies.set('isAdmin', user.id);
  }

  if (!process.env.NEXT_PUBLIC_BACKEND_URL) {
    throw new Error('NEXT_PUBLIC_BACKEND_URL is not set');
  }

  if (user) {
    Cookies.set('userId', user.id, {
      domain: process.env.NEXT_PUBLIC_BACKEND_URL.replace('https://', ''),
      path: '/',
      sameSite: 'None',
      secure: true,
    });
  }
  if (returnTo) {
    Cookies.remove('returnTo');
    window.location.replace(returnTo);
  }
};

export const unsetToken = () => {
  const cookieToRemove = ['userId', 'username', 'jwt', 'isAdmin', 'email'];

  cookieToRemove.forEach(cookie => {
    Cookies.remove(cookie);
  });
};

export const getUserFromLocalCookie = () => {
  return Cookies.get('username');
};

export const getUserIdFromLocalCookie = () => {
  return Cookies.get('userId');
};

// We re-declare the SessionProvider here to facility imports in other files
export const SessionProvider = NextAuthSessionProvider;
export const signOut = ({ callbackUrl = '/login', ...props }) => {
  unsetToken();
  return nextAuthSignOut({ callbackUrl, ...props });
};
export const signIn = nextAuthSignIn;
export const useSession = nextAuthUseSession;
