import { useCurrentUser } from '@/auth/useCurrentUser';

import { IconMenuItem } from './IconMenuItem';

export const MgmtAppIconMenuItem = () => {
  const currentUser = useCurrentUser();
  const roles = currentUser?.roleNames || [];

  const showAdminApp =
    roles.includes('sysAdmin') || roles.includes('sysManager');

  if (!showAdminApp) return null;

  return <IconMenuItem icon={'gear'} href="/manage" label="Management" />;
};
