import * as Sentry from '@sentry/nextjs';
import { Component } from 'react';

import Text from '@/components/data-display/Text';

class GlobalErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <Text>Sorry, something went wrong.</Text>
        </div>
      );
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //  @ts-ignore
    return this.props.children;
  }
}

export default GlobalErrorBoundary;
